/**************************
	drawer
*/
/**************************
	drawer
*/
.mm-page {
  --mm-size: 0%;
  --mm-max-size: 0%;
}

.mm-menu--fullscreen.mm-menu--opened ~ .mm-slideout {
  --mm-max-size: 0%;
}

.mm-wrapper--opened .mm-slideout {
  transform: none !important;
}

body.mm-wrapper--opened .root .header {
  background-color: #ffffff;
}

#drawer {
  width: 100%;
  top: 60px;
  z-index: 1000;
  box-shadow: 2px 2px 2px 2px #6f859b;
}
#drawer .drawer__bottom {
  flex-direction: column !important;
}

.mm-navbar {
  height: 60px;
  border-bottom: 1px solid #728397;
  background: transparent;
  background-size: cover;
  background-color: #ffffff;
}
.mm-navbar__title {
  justify-content: flex-start;
  padding-left: 0 !important;
}
.mm-navbar__title > span {
  color: #2c3746;
  font-size: 1.125rem;
}
.mm-navbar__btn {
  width: 30px;
}
.mm-navbar__btn::before {
  width: 10px;
  height: 10px;
  border-top: 2px solid #54677f;
  border-left: 2px solid #54677f;
  top: 3px;
  left: 10px;
}

.mm-panels {
  box-shadow: inset 0 4px 17px 0 rgba(111, 133, 155, 0.1882352941);
}

.mm-panel {
  padding: 0;
}
.mm-panel * {
  color: #475361;
}
.mm-panel::after {
  display: none;
}

#mm-1 .mm-navbar {
  display: none;
}

.mm-listitem {
  border-color: #ffffff;
  padding: 0;
  font-size: 1rem;
}
.mm-listitem__text._foot {
  padding: 0;
}
.mm-listitem__text::after {
  border-color: #e9e9e9;
}
.mm-listitem.li1 .mm-listitem__text, .mm-listitem.li2 .mm-listitem__text, .mm-listitem.li3 .mm-listitem__text, .mm-listitem.li4 .mm-listitem__text, .mm-listitem.li5 .mm-listitem__text {
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}
.mm-listitem__btn::after {
  content: "";
  display: inline-block;
  background: url("../img/icon/arrow/circle_blue_02.webp") 0 0 no-repeat transparent;
  background-size: 22px 22px;
  width: 22px;
  height: 22px;
}
.mm-listitem::after {
  border-bottom: 1px solid #e9e9e9;
  left: 0;
}

.mm-listitem__btn:not(.mm-listitem__text) {
  border: none;
  padding-right: 50px;
}

.mm-wrapper__blocker {
  background: rgba(0, 0, 0, 0);
}

.mm-menu--offcanvas {
  transition: 0.3s cubic-bezier(0.16, 0.96, 0.25, 0.91);
}
.mm-menu--offcanvas:not(.mm-menu--opened) {
  transform: translateX(100%);
}
.mm-menu--offcanvas.mm-menu--opened {
  transform: translateX(0);
}

.drawer__li {
  padding: 0;
}

.drawer__a {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  position: relative;
}
.sp_menu_area {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0;
}
.sp_menu_area._link .sp_link_list li:not(:last-of-type) {
  margin-bottom: 15px;
}
.sp_menu_area._link .sp_link_list a {
  max-width: 315px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 12px 5px;
  display: block;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 1.2;
  color: #213d2c;
  background-color: #eef2f0;
  font-size: 0.875rem;
  border-radius: 17px;
  transition: color, background-color 0.3s;
}
.sp_menu_area._action .sp_action_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.sp_menu_area._action .sp_action_list li a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111111;
  padding-top: 20px;
  padding-bottom: 20px;
}
.sp_menu_area._action .sp_action_list li span {
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: #111111;
  font-size: 0.875rem;
  margin-right: 10px;
}
.sp_menu_area._action .sp_action_list li._language {
  border-right: 1px solid #e9e9e9;
}
.sp_menu_area._action .sp_action_list li._language a {
  position: relative;
  display: flex;
  align-items: center;
  color: #111111;
}
.sp_menu_area._action .sp_action_list li._language img {
  width: 33px;
  height: auto;
}
.sp_menu_area._action .sp_action_list li._font_size {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list {
  display: flex;
  align-items: center;
}
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._s a,
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._l a {
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e7edf1;
  padding-top: 0;
  padding-bottom: 0;
}
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._s._selected,
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._l._selected {
  position: relative;
}
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._s._selected a,
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._l._selected a {
  position: relative;
  width: 33px;
  height: 33px;
  background-color: transparent;
  border-radius: 50%;
  color: transparent;
  text-fill-color: transparent;
  background-image: linear-gradient(to right, #2a8b8e, #61a256);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 100;
}
@media screen and (max-width: 1199px) {
  .sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._s._selected a,
  .sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._l._selected a {
    width: 29px;
    height: 29px;
  }
}
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._s._selected::before,
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._l._selected::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 31px;
  height: 31px;
  background: #ffffff;
  border-radius: 50%;
  z-index: 5;
}
@media screen and (max-width: 1199px) {
  .sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._s._selected::before,
  .sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._l._selected::before {
    width: 27px;
    height: 27px;
  }
}
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._s._selected::after,
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._l._selected::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 33px;
  height: 33px;
  background: linear-gradient(to right, #2a8b8e, #61a256);
  border-radius: 50%;
  z-index: 1;
}
@media screen and (max-width: 1199px) {
  .sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._s._selected::after,
  .sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._l._selected::after {
    width: 29px;
    height: 29px;
  }
}
.sp_menu_area._action .sp_action_list li._font_size .fontsize_list > ._s {
  margin-right: 5px;
}
.drawer__bottom {
  padding-bottom: 0;
}
.drawer__bottom::after {
  display: none;
}

.drawer__search {
  border-radius: 20px;
  max-width: 335px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
}
.drawer__search > .search_input .link {
  color: #ffffff !important;
}
.drawer__search > .search_input .link::before {
  background: url("../img/icon/arrow/link_arrow_white.webp") no-repeat;
  background-size: contain;
}
.drawer__search > .search_input .input {
  background-color: #f1f0f0 !important;
  color: #aeaeae !important;
}
.drawer__search > .search_input .input::placeholder {
  color: #aeaeae;
}
.drawer__search > .search_input .submit_btn {
  font-size: 0.938rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: block;
  width: 55px;
  height: 100%;
  position: absolute;
  margin: 0 0 0 auto;
  border: none;
  outline: none;
  color: #ffffff;
  background-color: #293751;
  z-index: 50;
  right: 0;
}