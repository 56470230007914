@use "import/var";

/**************************
	drawer
*/

$drawer_width: 100%;
$drawer_li_top_height: 61px;
$drawer_li_height: 70px;
$drawer_color: #222222;
$drawer_background-color: #ffffff;
$drawer_hover_background-color: #169296;
$drawer_hover_color: #ffffff;
$drawer_li_border_color: #dddddd;
$drawer_li_border_width: 1px;
// $drawer_arrow_color: #ff6600;
$drawer_mm_next_bg_color: transparent;
$drawer_mm_next_arrow_color: #222222;

$drawer_li_height: 50px;

/**************************
	drawer
*/

.mm-page {
	--mm-size: 0%;
	--mm-max-size: 0%;
}

.mm-menu--fullscreen.mm-menu--opened ~ .mm-slideout {
	--mm-max-size: 0%;
}

.mm-wrapper--opened .mm-slideout {
	transform: none !important;
}

body {
	&.mm-wrapper--opened {
		.root {
			.header {
				background-color: #ffffff;
			}
		}
	}
}

#drawer {
	width: 100%;
	top: 60px;
	z-index: 1000;
	box-shadow: 2px 2px 2px 2px #6f859b;

	.drawer {
		&__bottom {
			flex-direction: column !important;
		}
	}
}

.mm-navbar {
	height: 60px;
	border-bottom: 1px solid #728397;
	background: transparent;
	//background: url("../img/sp/sp_menu_bg.webp");
	background-size: cover;
	background-color: #ffffff;

	&__title {
		justify-content: flex-start;
		padding-left: 0 !important;

		> span {
			color: #2c3746;
			font-size: var.$fontsize18;
		}
	}

	&__btn {
		width: 30px;

		&::before {
			width: 10px;
			height: 10px;
			border-top: 2px solid #54677f;
			border-left: 2px solid #54677f;
			top: 3px;
			left: 10px;
		}
	}
}

.mm-panels {
	box-shadow: inset 0 4px 17px 0 #6f859b30;
}

.mm-panel {
	//background: url("../img/sp/sp_menu_bg.webp");
	//background-size: contain;
	//background-position: 0 0;
	padding: 0;

	* {
		color: #475361;
	}

	&::after {
		display: none;
	}
}

#mm-1 {
	.mm-navbar {
		display: none;
	}
}

.mm-listitem {
	border-color: #ffffff;
	padding: 0;
	font-size: var.$fontsize16;

	&__text {
		&._foot {
			padding: 0;
		}

		&::after {
			border-color: #e9e9e9;
		}
	}

	&.li1,
	&.li2,
	&.li3,
	&.li4,
	&.li5 {
		.mm-listitem__text {
			padding-left: 30px;
			padding-top: 20px;
			padding-bottom: 20px;
			position: relative;

			//&::before {
			//	display: block;
			//	content: "";
			//	width: 7px;
			//	height: 11px;
			//	background: url("../img/icon/arrow/sp_menu_arrow2.webp") no-repeat;
			//	background-size: contain;
			//	position: absolute;
			//	inset: 0 auto 0 13px;
			//	margin: auto;
			//}
		}
	}

	&__btn {
		&::after {
			content: "";
			display: inline-block;
			background: url("../img/icon/arrow/circle_blue_02.webp") 0 0 no-repeat transparent;
			background-size: 22px 22px;
			width: 22px;
			height: 22px;

			//border-top: 1px solid #566980;
			//border-left: 1px solid #566980;
			//width: 10px;
			//height: 10px;
			//left: 15px;
		}
	}

	&::after {
		border-bottom: 1px solid #e9e9e9;
		left: 0;
	}
}

.mm-listitem__btn:not(.mm-listitem__text) {
	border: none;
	padding-right: 50px;

	//&::before {
	//	display: block;
	//	content: "";
	//	width: 1px;
	//	height: calc(100% - 20px);
	//	background-color: #c2ccdd;
	//	position: absolute;
	//	inset: 0 auto 0 0;
	//	margin: auto;
	//	border: none;
	//}
}

.mm-wrapper__blocker {
	//display: none;
	background: rgb(0 0 0 / 0%);
}

.mm-menu--offcanvas {
	transition: 0.3s cubic-bezier(0.16, 0.96, 0.25, 0.91);

	&:not(.mm-menu--opened) {
		transform: translateX(100%);
	}

	&.mm-menu--opened {
		transform: translateX(0);
	}
}

.drawer__li {
	padding: 0;
}

.drawer__a {
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 30px;
	position: relative;

	&::before {
		//display: block;
		//content: "";
		//width: 19px;
		//height: 19px;
		//background: url("../img/icon/arrow/sp_menu_arrow.webp") no-repeat;
		//background-size: contain;
		//position: absolute;
		//inset: 0 auto 0 1px;
		//margin: auto;
	}
}

.sp_menu_area {
	margin-top: 30px;
	margin-bottom: 30px;
	padding: 0;

	&._link {
		.sp_link_list {
			li {
				&:not(:last-of-type) {
					margin-bottom: 15px;
				}
			}

			a {
				max-width: 315px;
				box-sizing: border-box;
				margin: 0 auto;
				padding: 12px 5px;
				display: block;
				text-align: center;
				letter-spacing: 0.05em;
				line-height: 1.2;
				color: #213d2c;
				background-color: #eef2f0;
				font-size: 0.875rem;
				border-radius: 17px;
				transition: color, background-color 0.3s;
			}
		}
	}

	&._action {
		.sp_action_list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			border-top: 1px solid #e9e9e9;
			border-bottom: 1px solid #e9e9e9;

			li {
				a {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #111111;
					padding-top: 20px;
					padding-bottom: 20px;
				}

				span {
					line-height: 1.2;
					letter-spacing: 0.05em;
					color: #111111;
					font-size: var.$fontsize14;
					margin-right: 10px;
				}

				&._language {
					border-right: 1px solid #e9e9e9;

					a {
						position: relative;
						display: flex;
						align-items: center;
						color: #111111;
					}

					img {
						width: 33px;
						height: auto;
					}
				}

				&._font_size {
					display: flex;
					align-items: center;
					justify-content: center;

					.fontsize_list {
						display: flex;
						align-items: center;

						> ._s,
						> ._l {
							a {
								display: flex;
								flex-shrink: 0;
								cursor: pointer;
								align-items: center;
								width: 30px;
								height: 30px;
								border-radius: 50%;
								background-color: #e7edf1;
								padding-top: 0;
								padding-bottom: 0;
							}

							&._selected {
								position: relative;

								a {
									position: relative;
									width: 33px;
									height: 33px;
									background-color: transparent;
									border-radius: 50%;
									color: transparent;
									text-fill-color: transparent;
									background-image: linear-gradient(to right, #2a8b8e, #61a256);
									background-clip: text;
									-webkit-text-fill-color: transparent;
									z-index: 100;

									@media #{var.$lg} {
										width: 29px;
										height: 29px;
									}
								}

								&::before {
									content: "";
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									width: 31px;
									height: 31px;
									background: #ffffff;
									border-radius: 50%;
									z-index: 5;

									@media #{var.$lg} {
										width: 27px;
										height: 27px;
									}
								}

								&::after {
									content: "";
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									width: 33px;
									height: 33px;
									background: linear-gradient(to right, #2a8b8e, #61a256);
									border-radius: 50%;
									z-index: 1;

									@media #{var.$lg} {
										width: 29px;
										height: 29px;
									}
								}
							}
						}

						> ._s {
							margin-right: 5px;
						}

						> ._l {
						}
					}
				}
			}
		}
	}
}

.drawer__bottom {
	padding-bottom: 0;

	&::after {
		display: none;
	}
}

.drawer__search {
	border-radius: 20px;
	max-width: 335px;
	margin: 0 auto;
	width: 100%;
	box-sizing: border-box;
	padding: 0;

	> .search_input {
		//display: flex;
		//flex-direction: column;
		//align-items: flex-start;
		//row-gap: 15px;

		.link {
			color: #ffffff !important;

			&::before {
				background: url("../img/icon/arrow/link_arrow_white.webp") no-repeat;
				background-size: contain;
			}
		}

		.input {
			background-color: #f1f0f0 !important;
			color: #aeaeae !important;

			&::placeholder {
				color: #aeaeae;
			}
		}

		.submit_btn {
			font-size: var.$fontsize15;
			font-weight: 500;
			letter-spacing: 0.05em;
			display: block;
			width: 55px;
			height: 100%;
			position: absolute;
			//inset: -2px auto 0 8px;
			margin: 0 0 0 auto;
			border: none;
			outline: none;
			color: #ffffff;
			background-color: #293751;
			z-index: 50;
			right: 0;
		}
	}
}
